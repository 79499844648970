import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 5×2 to a 2RM`}</p>
    <p>{`Lateral Band Walks 5×6/side`}</p>
    <p>{`then,`}</p>
    <p>{`30-Calorie Assault BIke`}</p>
    <p>{`15-Clean & Jerks (115/75)(RX+ 135/95)`}</p>
    <p>{`24-Calorie Assault Bike`}</p>
    <p>{`12-Clean & Jerks (135/95)(165/115)`}</p>
    <p>{`18-Calorie Assault Bike`}</p>
    <p>{`9-Clean & Jerks (155/105)(185/125)`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      